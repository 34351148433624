import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "__barrel_optimize__?names=ConfigProvider!=!/app/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Divider","Flex"] */ "__barrel_optimize__?names=Divider,Flex!=!/app/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/Inter-VariableFont_opsz,wght.ttf\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/public/icons/arrowUp.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icons/v.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icons/vUp.svg");
;
import(/* webpackMode: "eager" */ "/app/public/images/gpbLogo.svg");
;
import(/* webpackMode: "eager" */ "/app/public/images/pirsonLogo.svg");
;
import(/* webpackMode: "eager" */ "/app/public/images/rosLogoLight.svg");
;
import(/* webpackMode: "eager", webpackExports: ["Navigation"] */ "/app/src/components/Navigation/Navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["YandexMetrika"] */ "/app/src/components/YandexMetrika/YandexMetrika.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/BugReport/BugReport.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/DirectContractFormModal/DirectContractFormModal.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/KtruInfos/KtruInfos.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/OldVersionReport/OldVersionReport.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/ReportFormModal/ReportFormModal.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/shared/config/moment/MomentConfig.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/resets.scss");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.scss");
;
import(/* webpackMode: "eager" */ "/app/src/styles/colors.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/styles/colors/ros-colors.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/CharacteristicsFilter/CharacteristicsFilter.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/CurrentPage/CurrentPage.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/Docs/DocsPage.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/Header/Header.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/NmckAnalysis/NmckAnalysisPage.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/NmckGuard/NmckGuardPage.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/NmckHistory/NmckHistoryPage.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/OkpdDetail/ui/OkpdDetailPage/OkpdDetailPage.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/PenaltiesCalc/ui/PenaltiesCalcPage/PenaltiesCalcPage.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/PlanningAll/ui/PlanningAllPage.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/PlanningCurrent/PlanningCurrentPage.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/ReestrAll/ui/ReestrAllPage.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/ReestrDirect/ReestrDirectPage.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/Skp/SkpPage.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/SmpReport/ui/SmpReportPage.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/Summary/ui/SummaryPage/SummaryPage.tsx");
