'use client';

import { PropsWithChildren } from 'react';
import moment from 'moment';
import 'moment/locale/ru';

export const MomentConfig = ({ children }: PropsWithChildren) => {
  moment.updateLocale('ru', null);

  return children;
};
