'use client';

import { memo, useEffect } from 'react';
import Script from 'next/script';
import { usePathname, useSearchParams } from 'next/navigation';
import { sendHitYM } from '@/metrika';

export const YandexMetrika = memo(() => {
  const ymId = process.env.NEXT_PUBLIC_YANDEX_METRIKA_ID;

  const pathName = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    sendHitYM();
  }, [pathName, searchParams]);

  return (
    <Script id="yandex-metrika">
      {`
        (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
        m[i].l=1*new Date();
        for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
        k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
        (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

        ym(${ymId}, "init", {
          defer: true,
          clickmap:true,
          trackLinks:true,
          accurateTrackBounce:true,
          webvisor:true
        });   
      `}
    </Script>
  );
});

YandexMetrika.displayName = 'YandexMetrika';
